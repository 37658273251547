import Vue from 'vue'
import App from './App.vue'
import VueCookies from 'vue-cookies'
import Vuetify from 'vuetify';
import 'vuetify/dist/vuetify.min.css';
import '@mdi/font/css/materialdesignicons.css';
import '@/css/customStyles.css'
Vue.config.productionTip = false

Vue.use(VueCookies)
Vue.use(Vuetify);

const vuetify = new Vuetify({
  icons: {
    iconfont: 'mdi', // Configura el tipo de fuente de iconos
  },
});

new Vue({
  vuetify,
  render: h => h(App),
}).$mount('#app')
