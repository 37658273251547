<template>
  <div class="login-page" style="background-size: cover;"
    :style="{ 'background-image': 'url(' + require(`../assets/images/login/${businessBack}`) + ')' }">
    <v-container>
      <v-layout row class="mt-10 d-flex justify-center" >
        <v-flex col-md-8 col-sm-12 style="width: 569px;">
          <div class="login-form">
            <div style="display: flex;justify-content: space-between; align-items: center;" class="logos-container">
              <img :src="require('../assets/images/logo/muchiwa.png')" alt="logo" width="60px" />
              <img :src="require(`../assets/images/logo/${businessLogo}`)" alt="logo" width="320px" />
              <img :src="require('../assets/images/logo/InnovaLogo.png')" alt="logo" width="100px" />
            </div>
            <h1>Inicio de Sesión</h1>
            <v-alert border="left" v-if="error" dense color="orange" type="info">{{ error }}</v-alert>
            <v-form class="mx-2 my-2" @submit.prevent="login">
              <v-text-field v-model="username" @input="convertToLowerCase" dense filled outlined placeholder="usuario" @keyup.enter="login"
                required></v-text-field>
              <v-text-field v-model="password" type="password" placeholder="contraseña" dense filled outlined
                @keyup.enter="login" required></v-text-field>
              <v-btn class="btnsave my-2" type="submit">Ingresar</v-btn>
            </v-form>
          </div>
        </v-flex>

      </v-layout>

    </v-container>
  </div>
</template>

<script>
import axios from 'axios'

export default {
  name: "LoginMaster",
  data() {
    return {
      username: '',
      password: '',
      error: '',
      businessBack: process.env.VUE_APP_BUSINESS_BACK,
      businessLogo: process.env.VUE_APP_BUSINESS_LOGO
    }
  },
  computed: {
    greeting() {
      return `Welcome, ${this.username || 'guest'}!`
    }
  },
  methods: {
    convertToLowerCase() {
      this.username = this.username.toLowerCase()
    },
    getCookie(cname) {
      let name = cname + "=";
      let decodedCookie = decodeURIComponent(document.cookie);
      let ca = decodedCookie.split(";");
      for (let i = 0; i < ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) == " ") {
          c = c.substring(1);
        }
        if (c.indexOf(name) == 0) {
          return c.substring(name.length, c.length);
        }
      }
      return "";
    },

    async login() {
      if (this.username && this.password) {
        try {
          const response = await axios.post(`${process.env.VUE_APP_BACKEND_URL}/loginmaster`, {
            username: this.username,
            password: this.password,
            businessType: process.env.VUE_APP_BUSINESS_TYPE
          }, {
            headers: {
              'Content-Type': 'application/json',
              'User-Agent': window.navigator.userAgent
            }
          });

          this.error = ''
          //let BusinessName = response.data[0].BusinessName -> en JS los "let" estan hechos para varaiables que cambian de valor, si no deben utilizarse los "const"
          const { FrontendUrl, token } = response.data;
          //Creando cookies
          //this.$cookies.set('BusinessName', BusinessName, { domain: response.data[0].FrontendUrl } )
          //this.$cookies.set('BackendUrl', BackendUrl, { domain: response.data[0].FrontendUrl })
          //this.$cookies.set('ID_KeyMaster', '[ "'+ ID_KeyMaster+ '" ]', { domain: response.data[0].FrontendUrl })
          const now = new Date();
          now.setFullYear(now.getFullYear() + 1);
          document.cookie = `frontendUrl=${response.data.FrontendUrl};expires=${now.toUTCString()};domain=${window.location.hostname};path=/`;

          window.location.href = `${FrontendUrl}/#/login?token=${token}`

        }
        catch (error) {
          console.log(error);
          this.error = 'Autenticación fallida, por favor verifique sus credenciales.';
        }
      } else {
        this.error = 'Por favor ingrese usuario y contraseña.'
      }
    }
  }
}
</script>

<style scoped>
.login-form {

  background-color: white;
}

.logos-container {
  padding-top: 40px;
  padding-left: 40px;
  padding-right: 40px;
  padding-bottom: 40px;
}
.login-page {
  background-color: whitesmoke;
  width: 100% !important;
  max-width: 100% !important;
  height: 100% !important;
  max-height: 100% !important;
}


.error {
  color: red;
}
</style>