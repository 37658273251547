<template>
  <div id="app" 
  :style="{'background-image': 'url(' + require(`./assets/images/login/${businessBack}`) + ')'}"
  >
    <v-app>
      <LoginMaster msg="Welcome to Your Vue.js App"/>
    </v-app>
    </div>
</template>

<script>
import LoginMaster from './components/LoginMaster.vue'

export default {
  name: 'App',
  data() {
    return {
      businessBack: process.env.VUE_APP_BUSINESS_BACK
    }
  },  
  components: {
    LoginMaster
  },
  mounted() {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString)
    console.log(urlParams.get('redirect') != 1);
    if(urlParams.get('redirect') != 1){
      const frontendUrl = this.$cookies.get('frontendUrl')
      if(frontendUrl){
        window.location.href = frontendUrl;
      }
    } else {
      
      if(this.$cookies.get('frontendUrl')){
        this.$cookies.remove('frontendUrl');
        window.location = window.location.href.replace('?redirect=1', '#');
        // console.log('location ', window.location);
        window.location.reload();
      }
    }
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;

}
</style>
